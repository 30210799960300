import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Title from '../src/components/Title';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Title mdxType="Title" />
    <h2 {...{
      "id": "acerca-de"
    }}>{`Acerca de`}</h2>
    <p>{`Este manual está hecho en Gatsby, y su contenido en markdown (.md) o  markdown mdx (.mdx) y está hosteado en netlify.`}</p>
    <h3 {...{
      "id": "subir-documentos"
    }}>{`Subir documentos`}</h3>
    <p>{`Para subir un documento, bastá con agregar dentro de la carpeta docs el archivo MD o MDX, y formatearlo acordemente.
Una vez subido, se hace commit a los cambios del git, y netlify automaticamente detectará el cambio en el repositorio. Eso genera
un build, y se vuelve a construir la página con los nuevos cambios`}</p>
    <h3 {...{
      "id": "como-escribir-archivos-markdown"
    }}>{`Como escribir archivos markdown`}</h3>
    <p>{`Simplemente creas un archivo con tu editor preferido, incluso se puede con notepad, y lo guardas como .md
Para saber como escribir un archivo markdown, podes utilizar este cheatsheat `}<strong parentName="p">{`muy bueno`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
      }}>{`https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet`}</a></p>
    <h3 {...{
      "id": "code-highlighting"
    }}>{`Code Highlighting`}</h3>
    <p>{`Si querés agregar código, simplemente basta con tomar el formato que se usa para markdown, y automaticamente se va a resaltar según el lenguaje elegido.
por ejemplo PHP`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`<?php
    while(1) {
        print "In loop!\\n";
    }
?>

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      